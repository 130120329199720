import React from "react"

const GalleryCard = props => {
  return (
    <div
      className={`gallery-card ${
        props.reverse ? "gallery-card--reverse" : ""
      } ${props.alt ? " gallery-card--alt" : ""}`}
    >
      <img
        src={props.imgSrc}
        alt={props.imgAlt}
        className={`gallery-card__image ${
          props.vertical ? "gallery-card__image--vertical" : ""
        }`}
      />
      <div className="gallery-card__content-wrapper">
        <div className="gallery-card__content">
          <h4 className="gallery-card__heading">{props.heading}</h4>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default GalleryCard
