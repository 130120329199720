import React from "react"
import Layout from "../components/layout"
import GalleryCard from "../components/galleryCard"

export default function WingammMotorhomes() {
  return (
    <Layout>
      <section className="content content--fullwidth gallery">
        <h2 className="page__heading">
          Introducing the Wingamm Oasi 540
        </h2>
        <h3 className="page__subheading">
          The most compact, luxurious, technologically advanced motorhome in the
          world
        </h3>
        <div className="gallery-cards">
          <GalleryCard
            imgSrc="/oasi540/monocoque-shell.jpg"
            imgAlt="A worker lowers the monocoque shell onto the motorhome chassis"
            heading="Monocoque Shell"
            alt
          >
            <p className="gallery-card__description">
              Wingamm makes all living cells in a single piece of fiberglass
              (without any joints), borrowing construction techniques from the
              nautical and aeronautical sectors and guaranteeing the avant-garde
              in terms of water-tightness, solidity and durability.
            </p>
          </GalleryCard>
          <GalleryCard
            imgSrc="/oasi540/folding-bed.jpg"
            imgAlt="The motorhome bed in the dropped position"
            heading="Drop Down Bed"
            reverse
          >
            <p className="gallery-card__description">
              When not being used, the bed recesses into the ceiling so to not
              use up space. Directly above the bed are two skylights for views
              of the stars and recessed lighting for reading or working at
              night. When not being used, the bed is out of reach so there is no
              risk of tracking dirt into the van and onto your bed. The bed's
              dimensions are 77.5 x 53 inches, the equivalent to a standard
              double bed. Did we mention there are two beds? The second bed is
              converted from the dining room table. The size of the single bed
              (dinette conversion) is 74.5 x 27.5 inches.
            </p>
          </GalleryCard>
          <GalleryCard
            imgSrc="/oasi540/bathroom.jpg"
            imgAlt="Overhead view of shower, toilet, and washroom sink"
            heading="Full Washroom"
            alt
          >
            <p className="gallery-card__description">
              The interior of the bathroom is so spacious and functional it
              feels like that of a small hotel or apartment bathroom - and it
              functions like one as well. There is a standing shower (with hot
              water of course), a standard size toilet with soft closing lid, a
              full sink &amp; vanity with countertop space, a towel rack, a cup
              holder, and a medicine cabinet with a mirror.
            </p>
          </GalleryCard>
          <GalleryCard
            imgSrc="/oasi540/kitchen.png"
            imgAlt="Kitchen showcasing stove, sink, and countertop space"
            heading="Mini Chef's Kitchen"
            reverse
          >
            <p className="gallery-card__description">
              The kitchen comes complete with a two burner stove, a sink, a
              refrigerator/freezer and plenty of countertop space. Inspired by
              the curved lines typically seen in multi-million dollar yachts,
              the rounded cabinetry is designed to create a feeling of serenity
              and flow. All cabinets come with soft closing hinges for a
              luxurious feel like that of a state of the art kitchen.
            </p>
          </GalleryCard>
          <GalleryCard
            imgSrc="/oasi540/sewage-cassette.jpg"
            imgAlt="Looking into the outside panel and installed sewage cassette"
            heading="Advanced Sewage Cassette System"
            alt
          >
            <p className="gallery-card__description">
              The state of the art sewage cassette system puts an end to the
              biggest pain point in the RV industry - emptying sewage. The
              cassette system is far easier, simpler, more sanitary, and way
              more convenient than anything else offered in motorhomes
              manufactured in America. The tank has a catalyst that rapidly
              converts the sewage to "grey water" so it has little to no smell
              and no material when it's emptied. The 5 gallon tank can be easily
              emptied in any toilet in America.
            </p>
          </GalleryCard>
          <GalleryCard
            imgSrc="/oasi540/compact-length.png"
            imgAlt="Motorhome parallel parked between two cars in a city"
            heading="Compact Length"
            reverse
          >
            <p className="gallery-card__description">
              Only 8" longer than the Honda Odyssey minivan, the Wingamm Oasi
              540 is the most compact luxury motorhome in the world. Yet because
              of its fiberglass monocoque shell the shortness in length doesn't
              come at the expense of interior space. The standard parking space
              in the US is 19 feet long and the Wingamm is 17.78 feet long. By
              comparison RVs built on the Mercedes Sprinter and Ford Transit are
              at least 20-24 feet long.
            </p>
          </GalleryCard>
          <GalleryCard
            imgSrc="/oasi540/radiant-heat.jpg"
            imgAlt="Illustration of flooring radiant heat system"
            heading="State of the Art Radiant Heat System"
            alt
          >
            <p className="gallery-card__description">
              The Wingamm Oasi 540 comes standard with radiant heat under the
              floor. Wake up in the morning to heated floors and keep a nice
              ambient temperature spread evenly throughout the RV's interior all
              night.
            </p>
          </GalleryCard>
          <GalleryCard
            imgSrc="/oasi540/boosted-cell-wifi.jpg"
            imgAlt="Van with illustrated connectivity icons"
            heading="Boosted Cell Service and Wifi Hotspot"
            reverse
          >
            <p className="gallery-card__description">
              To ensure you never lose connectivity all Squirrels are equipped
              with a cell service signal booster that helps ensure that no
              matter where you roam you’ll stay connected.
            </p>
          </GalleryCard>
          <GalleryCard
            imgSrc="/oasi540/floorplan.png"
            imgAlt="Overhead cutaway of complete motorhome layout"
            heading="Spacious Floorplan"
            alt
          >
            <p className="gallery-card__description">
              Wingamm's custom fiberglass monocoque shell increases overall
              interior square feet when compared to similar sized motorhomes
              forced to build their interiors in Ford Transits, Mercedes
              Sprinters, and Dodge Promasters. This increased square footage
              creates an interior experience that mirrors the feeling of a small
              hotel room, all while being 2ft to 4 ft shorter than the
              aforementioned brands.
            </p>
          </GalleryCard>
        </div>
      </section>
    </Layout>
  )
}
